<template>
  <div class="friendship">
    <div class="top"></div>
    <div class="label">友情链接</div>
    <div class="main">
      <div class="linkList">
        <a class="linkItem" v-for="item in frendList" :key="item.id" :href="item.link_url">
          <img
            :src="item.logo"
            alt=""
          />
          <div class="name">{{ item.name }}</div>
          <div class="link-url">{{ item.show_url }}</div>
          <div class="desc">{{ item.desc }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "friend_ship",
  data() {
      return {
          frendList:[]
      }
  },
  mounted() {
      this.getFriendList()
  },
  methods: {
     async getFriendList(){
          const {data:res} = await this.$http.get('friendship_link/')
          if(res.code!=='200') return
          this.frendList = res.data
      }
  },
};
</script>

<style lang='less'>
.friendship {
  width: 100%;

  .label {
    width: 100%;
    height: 40px;
    padding: 10px;
    margin-top: 15px;
  }

  .top {
  }
  .main {
    .linkList {
      display: flex;
      flex-wrap: wrap;
      .linkItem {
        background: #fff;
        padding-left: 5px;
        margin: 10px 10px;
        width: 300px;
        border-radius: 30px;
        box-shadow:  2px 2px 5px rgb(206, 206, 206);
        img {
          float: left;
          margin: 15px 5px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #b1b1b1;
        }
        .name {
          float: left;
          width: 200px;
          height: 40px;
          padding-top: 18px;
          font-size: 18px;
           text-align: center;
          display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 1;

        }
        .link-url{
           float: left;
           width: 200px;
           font-size: 12px;
           text-align: center;
           color: rgb(168, 168, 168);
        }
        .desc {
          float: left;
          width: 200px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          color: rgb(139, 139, 139);
           display: -webkit-box;
            // 不换行
            overflow: hidden;
            -webkit-box-orient: vertical;
            // 俩点S
            -webkit-line-clamp: 1;
        }
      }
    }
  }
}
</style>